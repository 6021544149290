import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorTheme } from 'redux/theme/themeSelectors';
import { toggle as toggleTheme } from 'redux/theme/themeSlice';
import s from './ThemeSwitcher.module.css';

export default function ThemeSwitcher() {
  const dispatch = useDispatch();
  const theme = useSelector(selectorTheme);

  const handleChange = () => {
    if (theme === 'day') {
      dispatch(toggleTheme('night'));
    } else {
      dispatch(toggleTheme('day'));
    }
  };

  useLayoutEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  return (
    <div className={s.toggleBtn}>
      <input
        type="checkbox"
        checked={theme === 'night'}
        onChange={handleChange}
      />
      <span>&nbsp;</span>
    </div>
  );
}
